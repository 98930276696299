import React from 'react';
import { BrowserRouter, NavLink, Switch, Route } from 'react-router-dom';
import './Header.css';
import '../fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from './Navigation';

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articles: props.articles
        }

    }
    
    render() { 
        return (
            <Container fluid style={{zIndex:'100', elevation:'100', position:'absolute', padding:'0px', margin:'0px', top:'0px'}}>
            <Row className="header_height header_style drop-shadow raised" style={{ position:'fixed', top:'0px', margin:'0px', width:'100%', padding:'0px', border:'none', overflow:'visible'}}>
                <Col className="d-none d-sm-none d-md-grid col-md-1" 
                    style={{border:'none'}} />
                <Col className="d-md-grid col-md-10" style={{border:'none'}}>
                    <Navigation />
                </Col>
                <Col className="d-none d-sm-none d-md-grid col-md-1" />
            </Row>
            </Container>
        )
    }
}

export default Header;
