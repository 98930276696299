import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

var loadingMsg;

export default class AppLoading extends React.Component { 

    constructor(props) {
        super(props);

        
    };

    render() {

        if(this.props.apiLoaded)
        {
            loadingMsg = '<img src="https://atomic-republic.b-cdn.net/Loading-Bars-1s-200px-WHITE-BG.gif" style="height:20px;" />'
        }

        return (
            <Container fluid>
            <Row className="mx-auto">
                <Col className="col-4"/>
                <Col className="col-4" style={{textAlign:'center'}}>
                    <div>
                        {ReactHtmlParser(loadingMsg)}<br/>
                    </div>
                </Col>
                <Col className="col-4"/>
            </Row>
            </Container>
        )
    }
}