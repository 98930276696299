import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import '../classes/GeneralClasses.js';
import APIManager from '../classes/APIManager';
import AppLoading from '../ui-base/AppLoading';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { TwitterCommunity } from '../classes/GeneralClasses.js';
import TwitterCommunityAddContainer from '../ui-base/TwitterCommunityAddContainer';

var apiManager = new APIManager();
var twitterCommunities = [];
var triggerText = "Add Community";
var showMore = false;
var showPrev = false;
var showMoreElement = null;

var tempHTML = "";

const styleObj = {
    color: 'red',
    fontSize: '10px'
};

class TwitterCommunities extends React.Component {

  constructor(props) {
    super(props); 
      this.state = {
          twitterCommunities: [],
          twitterCommunitiesHTML: "",
          twitterListTextHTMLBootstrap: "",
          twitterCommunitiesLoaded: false,
          selectedEnrollmentFilterValue: 'all',
          searchFilterValue: '',
          communitiesElementStored: [],
          formMessage: '',
          formSubmitted: false,
          totalPages: 1,
          currentPage: 1
      };

      this.getTwitterCommunities = this.getTwitterCommunities.bind(this);
      this.handleFilterChange = this.handleFilterChange.bind(this);
      this.handleSearchFilterChange = this.handleSearchFilterChange.bind(this);
      this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
      this.handleCategorySearchFilterChange = this.handleCategorySearchFilterChange.bind(this);
      this.handleSubmitNewCommunity = this.handleSubmitNewCommunity.bind(this);
      this.resetFormState = this.resetFormState.bind(this);
      this.addTwitterCommunitiesToStorage = this.addTwitterCommunitiesToStorage.bind(this);
      this.showMoreTwitterCommunities = this.showMoreTwitterCommunities.bind(this);
      this.clearDuplicates = this.clearDuplicates.bind(this);
      this.storeSearchFilterValue = this.storeSearchFilterValue.bind(this);
      this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount() {

    await apiManager.GetTwitterCommunitiesTotalPages(true, 24)
        .then((response) => {

          this.setState({totalPages: response.data});

        });

    this.getTwitterCommunities();
  }

  handleSubmitNewCommunity = async(event) => {
  
    event.preventDefault(event);
  
    this.setState({formSubmitted: false});
    this.setState({formMessage:''});
  
    var closeForm = true;
  
    var community_name = event.target.name.value;
    var link = event.target.link.value;
  
    if(community_name === '')
    {
       closeForm = false;
       //event.target.childNodes[0].html('TEST');
    }
  
    if(link === '')
    {
      closeForm = false;
    }
  
  
    if(closeForm){
      //submit the suggested Twitter Community
      await apiManager.SubmitTwitterCommunity(community_name, link)
        .then((response) => {
            console.log('temp');
        });

      this.setState({formSubmitted: true});
    }
    else 
    {
      this.setState({formMessage:'Both fields are required.'});
    }
  
  };

  resetFormState()
  {
    this.setState({formSubmitted: false});
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
       //console.log('enter press here! ')
       this.handleSearchFilterChange();
    }
  }

  handleCategorySearchFilterChange(searchFilter)
  {
    this.setState({searchFilterValue: searchFilter});
    this.state.searchFilterValue = searchFilter;

    this.searchVal.value = ""; //clears the search value, since a category was clicked

    //reset the current page to 1
    this.setState({currentPage:1});   
    this.state.currentPage = 1;

    //clear the stored communities
    twitterCommunities = [];

    this.getTwitterCommunities();
  }

  storeSearchFilterValue(event)
  {
    this.setState({searchFilterValue: event.target.value});
    this.state.searchFilterValue = event.target.value;
  }

  handleSearchFilterChange(event){

    //this.setState({searchFilterValue: event.target.value});
    //this.state.searchFilterValue = event.target.value;

    //reset the current page to 1
    this.setState({currentPage:1});   
    this.state.currentPage = 1;

    //clear the stored communities
    twitterCommunities = [];

    this.getTwitterCommunities();

  }

  handleFilterChange(event) {

    this.setState({selectedEnrollmentFilterValue: event.target.value});
    this.state.selectedEnrollmentFilterValue = event.target.value; 

    //reset the current page to 1
    this.setState({currentPage:1});  
    this.state.currentPage = 1; 

    this.getTwitterCommunities();

  }

  handleFilterSubmit(event) {

    this.getTwitterCommunities();
    event.preventDefault()
    //this.handleFilterSubmit(event);
  }

  showMoreTwitterCommunities = () => {

      var newPage = this.state.currentPage + 1;

      this.setState({currentPage:newPage});    
      
      this.getTwitterCommunities();

  }

  addTwitterCommunitiesToStorage = (communitiesArray) => {

      var addToArray = true;

      for(var i=0;i<communitiesArray.length;i++)
      {
        addToArray = true;

        for(var ii=0;ii<twitterCommunities.length;ii++)
        {
            if(twitterCommunities[ii].LinkURL == communitiesArray[i].linkURL)
            {
              addToArray = false;
            }
        }

        if(addToArray)
        {
          twitterCommunities.push(communitiesArray[i]);
        }

      }

      //console.log(twitterCommunities.length);

  }

  clearDuplicates  = (communitiesArray) => {

      var cleanedArray = [];
      var addToArray = true;

      for(var i=0;i<communitiesArray.length;i++)
      {
        addToArray = true;

        for(var ii=0;ii<twitterCommunities.length;ii++)
        {
            if(twitterCommunities[ii].LinkURL == communitiesArray[i].linkURL)
            {
              addToArray = false;
            }
        }

        if(addToArray)
        {
          cleanedArray.push(communitiesArray[i]);
        }

      }

      return cleanedArray;
  }

  getTwitterCommunities = async() => {

    var listTextHTML = "";
    var listTextHTMLBootstrap = "";
    var itemRowCount = 0;
    var itemRowCountMax = 4;
    var twitComms = [];

    var enrollmentFilter = "";
    var searchFilter = "";
    var communityImgLink = "";
    var communitiesElement = null;
    var communitiesElementColGroup = [];
    var communitiesElementRow = [];
    var rowClosed = false;
    var resultsPerPage = 24;

    if(this.state.selectedEnrollmentFilterValue === null || this.state.selectedEnrollmentFilterValue === 'all')
    {
      enrollmentFilter = "";
    }
    else 
    {
      enrollmentFilter = this.state.selectedEnrollmentFilterValue;
    }

    if(this.state.searchFilterValue != null && this.state.searchFilterValue != '')
    {
      searchFilter = this.state.searchFilterValue;
    }

    await apiManager.GetTwitterCommunitiesTotalPages(true, resultsPerPage, searchFilter, enrollmentFilter,"ACTIVE_ONLY")
    .then((response) => {

        this.setState({totalPages: response.data});

    });

    await apiManager.GetTwitterCommunities(searchFilter, enrollmentFilter,"ACTIVE_ONLY", this.state.currentPage)
    .then((resp) => {

        twitComms = resp;

        //store the Twitter Communities in an array if they are not already in the array
        this.addTwitterCommunitiesToStorage(twitComms);

        twitterCommunities = this.clearDuplicates(twitterCommunities);

        //twitterCommunities = twitComms;

        if(this.state.currentPage < this.state.totalPages)
        {
            showMoreElement = <Button variant="outline-secondary" onClick={(e) => this.showMoreTwitterCommunities()} className="twitter_category_see_more_button">See More</Button>;
        }
        else 
        {
           showMoreElement = null;
        }
      
        for(var i=0;i<twitterCommunities.length;i++)
        {

            if(itemRowCount == itemRowCountMax)
            {
              itemRowCount = 0;

              communitiesElementRow.push(<Row>{communitiesElementColGroup}</Row>)
              communitiesElementColGroup = []; //clear the group
              rowClosed = true;
            }
            
              rowClosed = false;
              itemRowCount = itemRowCount + 1;

              communityImgLink = `<a href="{twitterCommunities[i].LinkURL}" target="_blank" style="text-decoration:none;"><img src="{twitterCommunities[i].ImgSrcURL}" class="twitter_community_img"/></a>`;

              communitiesElementColGroup.push(<Col style={{margin:'4px', padding:'0px', minWidth:'200px', maxWidth:'25%'}}>
                <div className="twitter_community_box rect-img-container" style={{padding:'0px', width:'100%'}}>
                  <div style={{padding:'0px', overflow:'hidden', maxHeight:'25%', width:'100%'}}>
                    <a href={twitterCommunities[i].LinkURL} target="_blank" style={{textDecoration:'none'}}>
                    <img src={twitterCommunities[i].ImgSrcURL} className="twitter_community_img_boxed rect-img"></img>
                    </a>
                  </div>
                  <div style={{padding:'4px', position:'relative', marginTop: '50%'}}>
                    <span class="twitter_community_name">{twitterCommunities[i].Name}</span><br/>
                    <span class="twitter_community_general">Enrollment: {twitterCommunities[i].Enrollment}</span><br/><br/>
                    <span class="twitter_community_general">{twitterCommunities[i].Description}</span>
                  </div>
                </div>
                </Col>);

            

            listTextHTML = listTextHTML + `<div>`
            + `<a href="` + twitterCommunities[i].LinkURL + `" target="_blank" style="text-decoration:none;">`
            + `<img src="` + twitterCommunities[i].ImgSrcURL + `" class="twitter_community_img"/></a><br/>`
            + `<a href="` + twitterCommunities[i].LinkURL + `" target="_blank" style="text-decoration:none;">`
            + `<span class="twitter_community_name">` + twitterCommunities[i].Name + `</span></a><br/>` 
            + `<span class="twitter_community_general">Description: `+ twitterCommunities[i].Description + `</span><br/>`
            + `<span class="twitter_community_general">Enrollment: ` + twitterCommunities[i].Enrollment + `</span><br/>`
            + `<hr/>` 
            + '</div> ';

        }


        if(!rowClosed)
        {
          communitiesElementRow.push(<Row>{communitiesElementColGroup}</Row>)
          communitiesElementColGroup = []; //clear the group
        }

        for(var i=0;i<communitiesElementRow.length;i++)
        {
          communitiesElement = <Row>{communitiesElementRow}</Row>
        }

        communitiesElement = <Container>{communitiesElement}</Container>

        this.setState({twitterCommunitiesHTML: listTextHTML});
        //this.setState({totalElementsStoredCount: currentElementsStoredCount});
        this.setState({communitiesElementStored:communitiesElement})
        this.setState({twitterCommunitiesLoaded: true});


    })
    

  }


  render() {

  if(!this.state.twitterCommunitiesLoaded)
  {
    return (
      <div>
         <AppLoading status={this.state.status} apiLoaded={this.state.apiLoaded}/>
      </div>
    )
  } 
  else{ 
    return (
      <div className="single_page_post" style={{width:'100%', position:'relative', 
        backgroundColor:'#ddd', minHeight:'600px'}} onKeyPress={this.handleKeyPress}>
        <Container 
            style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}
             >                      
          <Row>
            <Col className="rounded_content_block article_content_box">  
              <Container fluid>
              <Row>             
                <Row>
                  <Col><h2 className="blog_list_headline">Twitter Communities</h2></Col>
                  <Col></Col>
                  <Col style={{textAlign:'right'}}>
                    <TwitterCommunityAddContainer triggerText={triggerText} onSubmit={this.handleSubmitNewCommunity} formMessage={this.state.formMessage} formSubmitted={this.state.formSubmitted} resetFormState={this.resetFormState}/>
                  </Col>
                </Row>
                <Row>
                  <Col><hr className="hr_article article_body_font"/></Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <div className="article_body_font">
                        <p className="twitter_community_general">
                          <span className="twitter_community_alert">Please Note: Twitter has added a 'Search' features for communities.<br/>
                          This tool will no longer be maintained after 3/21/2022 and will deprecated in the future.</span><br/>
                          Twitter Communities Search - while this is certainly not a complete list, this is the current list that 
                          we've been able to find or have been reported. Twitter Communities marked as 'Open' are open to all to join, 
                          as long as you abide by their posted rules. Twitter Communities marked as 'Closed' will require an invitation 
                          from an existing member.<br/> 
                        </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <select className="form-select form-select-sm mb-3" onChange={this.handleFilterChange}>
                          <option value="all" selected>Show All Communities</option>
                          <option value="open">Show Only Open Communities</option>
                          <option value="closed">Show Only Closed Communities</option>
                        </select>  
                      </Col>
                    </Row>
                     
                    <Row>
                        <Col xs={11}>
                          <input id="searchVal" className="form-control form-control-sm mb-3" onKeyUp={this.storeSearchFilterValue} placeholder="Community Keywords (optional)" type="text" style={{height:'40px'}} ref={el => this.searchVal = el}/>
                        </Col>  
                        <Col xs={1}>
                          <Button variant="outline-secondary" onClick={(e) => this.handleSearchFilterChange()}><FontAwesomeIcon icon={faMagnifyingGlass}/><FontAwesomeIcon icon="fa-solid fa-coffee" size="xs" /></Button>{' '}
                        </Col>
                      </Row>              
                    <Row>
                      <Col>
                        <div style={{width:'100%', textAlign:'center'}}>
                          <span>CATEGORIES</span><br/>

                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('')} className="twitter_category_button">Explore</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('startup business')} className="twitter_category_button">Business &amp; Startups</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('sports')} className="twitter_category_button">Sports</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('health')} className="twitter_category_button">Health &amp; Wellness</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('tech programming')} className="twitter_category_button">Tech</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('games')} className="twitter_category_button">Games</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('animals')} className="twitter_category_button">Animals</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('music')} className="twitter_category_button">Music</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('photography')} className="twitter_category_button">Photography</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('anime')} className="twitter_category_button">Anime</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('art')} className="twitter_category_button">Art</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('fashion')} className="twitter_category_button">Fashion</Button>{' '}                         
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('movies')} className="twitter_category_button">Movies &amp; Film</Button>{' '}
                          <Button variant="outline-secondary" onClick={(e) => this.handleCategorySearchFilterChange('travel')} className="twitter_category_button">Travel</Button>{' '}
                        </div>
                      </Col>
                    </Row>
                      
                    {/*<div dangerouslySetInnerHTML={{__html: this.state.twitterCommunitiesHTML}}/>*/}
       
                  </Col>
                </Row>

              </Row>

              {this.state.communitiesElementStored}

              <Row>
                <Col>
                <div style={{width:'100%', textAlign:'center'}}>
                  {showMoreElement}
                </div>
                </Col>
              </Row>
              </Container>
            </Col>
            
          </Row>
          
        </Container>
      </div>
    );
  }
    
  }

}

export default TwitterCommunities