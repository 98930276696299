import { NavLink } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import Container from 'react-bootstrap/Row';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//use this to override the default style
const nav_link_style = {
  fontSize:'20px',
  color:'white',
  fontFamily:'Anton'
};

const navbar_toggler_right = {
  borderColor: 'yellow'
};

const nav_stripe_img_url = "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='yellow' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E";

export const Navigation = () => (

<Navbar collapseOnSelect bg="transparent" expand="md" variant="dark" className="custom-toggler" style={{width:"100%", padding:'0px', border:'none'}}>
    {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
    <div style={{ width:'100%', margin:'0px', border:'none', backgroundColor:'#000'}}>
    <Row style={{ backgroundColor:'#000' }}>
        <Col className="d-md-grid col-md-12"  style={{border:'none'}}>
            <div style={{display: 'table', height: '100%', overflow: 'hidden'}}>
                <div style={{display: 'table-cell', verticalAlign:'middle', width:'10%', border:'none', backgroundColor:'#000'}}>
                    <div>
                    <img className="header_img img-responsive" src="https://atomic-republic-atomic6.b-cdn.net/LOGO-WHITE_ON_TRANSP_200w.png" 
                        style={{maxHeight:'130px', padding:'10px', float:'left'}}/> <br/>
                    </div>
                </div>
                <div style={{display: 'table-cell', verticalAlign:'middle', width:'20%', backgroundColor:'#000', height:'100%'}}>
                    <div className="header_display_title" style={{ color:'white', fontFamily:'Anton'}}>
                    
                    </div>
                </div>
                <div style={{display: 'table-cell', verticalAlign:'middle', 
                  textAlign:'right', width:'70%', border:'none', 
                  backgroundColor:'#000', paddingRight:'5px'}}>
                    <div style={{fontSize:'20px', color:'white', fontFamily:'Anton'}}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav custom-toggler">
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link href="/" style={nav_link_style}>Home</Nav.Link>
                        <Nav.Link href="/Articles" style={nav_link_style}>Articles</Nav.Link>
                        <Nav.Link href="/Products" style={nav_link_style}>Products</Nav.Link>
                        <Nav.Link href="/Resources" style={nav_link_style}>Resources</Nav.Link>
                        <Nav.Link href="/About" style={nav_link_style}>About</Nav.Link>
                        {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">
                          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                          </NavDropdown>*/}
                      </Nav>
                    </Navbar.Collapse>
                    </div>
                </div>
            </div>
        </Col>
        {/*
        <Col>
          <Navbar.Toggle aria-controls="basic-navbar-nav custom-toggler">
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" style={nav_link_style}>Home</Nav.Link>
              <Nav.Link href="/About" style={nav_link_style}>About</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Col>
        */}
    </Row>
    {/*
    <nav class="navbar navbar-dark" style={{backgroundColor:'#000'}}>
        <button class="navbar-toggler ml-auto custom-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar4">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="collapsingNavbar4">
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link" href="/">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/About">About</a>
                </li>
            </ul>
        </div>
    </nav>
    */}
    </div>
</Navbar>


  );
  
export default Navigation;