import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import '../classes/GeneralClasses.js';
import APIManager from '../classes/APIManager';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import LatestHeadlines from '../classes/LatestHeadlines';

var apiManager = new APIManager();

class TwitterCommunitiesInformation extends React.Component {

  constructor(props) {
    super(props); 
      this.state = {

      };


  }

  async componentDidMount() {

  }


  render() {
   
    return (
      <div className="single_page_post" style={{width:'100%', position:'relative', 
        backgroundColor:'#ddd', minHeight:'600px'}}>
        <Container 
            style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
          <Row>
            <Col className="rounded_content_block article_content_box">               
              <h2 className="blog_list_headline">Twitter Communities</h2>
              <hr className="hr_article article_body_font"/>
              <div className="twitter_community_guide_general">
                <p>
                <span className="twitter_community_guide_headline">Twitter Communities Guide</span><br/> 
                <span className="twitter_community_guide_blurb">How to use Twitter Communities to find 
                potential audiences of targeted users.</span>
                <br/><br/>
                Twitter Communities were introduced by Twitter in the Fall of 2021 as new way to 
                interact with others about a specific topic or interest. 
                <br/><br/>
                There are two types of Twitter Communities:<br/><br/>
                <ul>
                  <li>"Open", which means anyone with a Twitter account can join</li>
                  <li>"Closed" which means you have to recieve an invitation in order 
                        to join. Members of a community have five invitations they can offer to others.</li>
                </ul>
                Each Twitter Community has its own rules for posting or interacting with Tweets or other 
                community members; if you run afoul of the rules, you can be removed from the community 
                by moderators. Each community may have one or more moderators. 
                <br/><br/>
                When you post to a Twitter Community, you are able to select and target which community 
                you want to see your Tweet, and even though the Tweet will be visible to other members 
                of that community, the Tweet won't be shown to the rest of your followers. 
                <br/><br/>
                Not all Twitter Communities are created equal; some are self-promotional areas with little value and 
                some are ghost towns, with no one really interacting. You can jump in and try to jump start conversations 
                in some, but realize going in that you are either joining an active community with a specific culture 
                or you are joining a community with no culture and little activity. It's a good idea to try and determine 
                what the actvity level and culture is in a community before diving in headfirst. 
                <br/><br/>
                Some issues that may arise with Twitter Communities are that the conversations are basically in their 
                own ecosystem. Unless someone specifically navigates to a community and is a member, they will likely not 
                see much of the conversation. However, the converse benefit is that you can have different audiences for your 
                conversations, as your main feed with not see conversations you may be having/Tweeting about unless 
                they are also in that Twitter Community. 
                <br/><br/>
                Creating a community isn't straightforward; all communities have to be requested and 
                approved by Twitter. 
                <br/><br/>
                <b>How do I find communities?</b><br/><br/>
                There is a 'communities' tab on most Twitter interfaces that will either show you the communities 
                you have already joined or suggest a few for you. However, searching for communities is not currently 
                a Twitter feature and the process to fins existing communities can take some luck or potentially 
                someone you know on Twitter finds a community and either lets you know or sends you an invitation.
                However, all is not lost. you can find a larger <a href="twittercommunities">list of the Twitter 
                Communities</a> we've already discovered.  You can also check out 
                the <a href="https://twitter.com/i/communities/1493795120520564738" target="_blank">Atomic Startups &amp; Founders Twitter Community</a> where we all 
                gather and discuss ways to launch and grow our businesses and side hustles.
                <br/><br/>
                Twitter Communities are a great way to start paying attention to the conversations of a 
                community, finding the people who are involved in that community and often making 
                connections. As of this writing, one of the issues in using Twitter Communities is that 
                Twitter has not provided an easy way to find communities you might be interested in. 
                Hopefully this will become easier in the future, but for now you can check out our 
                <a href="twittercommunities"> Twitter Communities Search Engine</a>, and if you have 
                suggestions or updates for the list, please submit them.
                <br/><br/>
                The official documentation about Twitter Communities provided by Twitter can be 
                found <a href="https://help.twitter.com/en/using-twitter/communities" target="_blank">here</a>.
                </p>          
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
    
  }

}

export default TwitterCommunitiesInformation