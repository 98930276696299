import React from 'react';

import axios from 'axios';


export class CurrentUser{
    constructor(id, username) {
        this.id = id;
        this.username = username;
      }

    get FirstName() {
        return this.getFirstName();
      }

    getFirstName()
    {
        return "Bob";
    }

}

class Rectangle {
    constructor(height, width) {
      this.height = height;
      this.width = width;
    }
}

class SayHello extends React.Component {
    constructor(props) {
      super(props);
      this.state = {message: 'Hello!'};
    }
    // WARNING: this syntax is experimental!
    // Using an arrow here binds the method:
    handleClick = () => {
      alert(this.state.message);
    }
  
    render() {
      return (
        <button onClick={this.handleClick}>
          Say hello
        </button>
      );
      }
  }

  /*
  export const DelayComponent = () => {
    const [show, setShow] = React.useState(false)
  
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        setShow(true)
      }, 5000)
  
      return () => clearTimeout(timeout)
  
    }, [show])
  
    if (!show) return 'FALSE';
  
    return <>TRUE</>
  }
  */

export default class AppData extends React.Component{

    state = {
        articles: [],
        status: "NOT LOADED",
        loaded: false
      }

    constructor(props) {
        super(props);
        this.updateArticles = this.updateArticles.bind(this);
        this.getArticles = this.getArticles.bind(this);
        this.Status = this.Status.bind(this);
        this.updateArticles();
        //this.setState({ status: "LOADED" });
        //this.state.status = "LOADED";
    };

    componentDidMount() {

        this.state.loaded = false;

        setTimeout(function() { //Start the timer
            this.setState({loaded: true}) //After 1 second, set render to true
        }.bind(this), 2000)
      }

    Status()
    {
        if(this.state.loaded)
        {
            this.state.status = "LOADED";
        }
        else 
        {
            this.state.status = "NOT LOADED";
        }

        return this.state.status;
    }

    updateArticles() {
        this.setState({ articles: this.getArticles()});    
    }

    getArticles()
    {
        const articles =  [
            {id:"1", title: "Title 1", body: "The body of article 1", publish_date: "10/3/2021"},
            {id:"2", title: "Title 2", body: "The body of article 2", publish_date: "10/4/2021"},
            {id:"3", title: "Title 3", body: "The body of article 3", publish_date: "10/5/2021"},
        ]
    
        return articles;
    }

    render() {

        if(!this.state.loaded)
        {
            return <>FALSE</>;
        }
        else {
            return <>TRUE</>;
        }

    }



    /*
    render() {

        let renderContainer = false //By default don't render anything
        if(this.state.render) { //If this.state.render == true, which is set to true by the timer.
            renderContainer = <div>Look at me! I'm content!</div> //Add dom elements
        }
        return (
            renderContainer
        )
      }
*/
}



export class PersonList extends React.Component {
    state = {
      persons: []
    }
  
    componentDidMount() {
      axios.get(`https://jsonplaceholder.typicode.com/users`)
        .then(res => {
          const persons = res.data;
          this.setState({ persons });
        })
    }
  
    render() {
      return (
        <ul>
          { this.state.persons.map(person => <li>{person.name}</li>)}
        </ul>
      )
    }
  }