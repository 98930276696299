import React from 'react';
import axios from 'axios';

var apiURL;
var timeoutSpan = 5000; //the number of milliseconds to attempt to connect to the API

export default class APIChecker extends React.Component{
    state = {
        apiResult: null,
        status: "NOT LOADED",
        loaded: false
    }

    constructor(props) {
        super(props);
        this.UpdateAPIHealth = this.UpdateAPIHealth.bind(this);
    };

    //componentDidMount() {

    //this.state.loaded = false;

    /*
        setTimeout(function() { //Start the timer
            this.setState({loaded: true}) //After 1 second, set render to true
        }.bind(this), 2000)
    }
    */

    componentWillMount() {

    }


    componentDidMount() {

        /*
        setTimeout(function() { //Start the timer
            this.setState({loaded: true, status: 'FAIL:TIMOUT'}) 
        }.bind(this), timeoutSpan)
        */
        
        apiURL = `http://apidev.modeworx.com/api/1.0/App/TestConnect`;

        axios.get(apiURL)
        .then(resp => {
            //console.log(resp.data);
            //apiHealthy = resp.data;
            //this.setState({apiHealthy: resp.data}) 

            this.state.apiHealthy = resp.data;

            if (this.state.apiHealthy.code === 'OK')
            {
                this.setState({status: 'GOOD', loaded: true});
                this.state.loaded = true;
            }
            else 
            {
                this.setState({status: 'FAIL', loaded: true});
                this.state.loaded = true;
            }

        })
        .then(



        )
        .catch(err => {
            // Handle Error Here
            console.error(err);
            //apiHealthy = 'FALSE';
            this.setState({status: 'FAIL', loaded: true});
        });

    
    }

    async UpdateAPIHealth()
    {
        /*
        setTimeout(function() { //Start the timer
            this.setState({loaded: true, status: 'FAIL:TIMOUT'}) 
        }.bind(this), timeoutSpan)
        */
       console.log('test');

        //apiURL = `https://api.notifibot.com/api/1.0/Home/TestConnect/`;
        apiURL = `http://apidev.modeworx.com/api/1.0/App/TestConnect/`;

        await axios.get(apiURL)
        .then(resp => {
            //console.log(resp.data);
            //apiHealthy = resp.data;
            //this.setState({apiHealthy: resp.data}) 

            //let obj2 = JSON.parse(testJSON);
            //let obj = resp.data;

            this.state.apiHealthy = resp.data;

            if (this.state.apiHealthy.Result === 'OK')
            {
                //this.setState({status: 'GOOD', loaded: true});
                this.state.loaded = true;
                this.state.status = 'GOOD';
            }
            else 
            {
                //this.setState({status: 'FAIL', loaded: true});
                this.state.loaded = true;
                this.state.status = 'FAIL';
            }

        })
        .then(



        )
        .catch(err => {
            console.error(err);
            //this.setState({status: 'FAIL', loaded: true});
            this.state.loaded = true;
            this.state.status = 'FAIL';
        });
    }

    async CheckApiHealth(){

        await this.UpdateAPIHealth();

        return this.state.status;
    }

    CheckExistence()
    {
        return 'DONE';
    }

}

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }