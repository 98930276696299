import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

const About = () => (

  <div className="single_page_post" style={{width:'100%', position:'relative', 
  backgroundColor:'#ddd', minHeight:'600px'}}>
  <Container 
      style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
      <Row>
          <Col className="rounded_content_block article_content_box">               
          <h2 className="blog_list_headline">About Atomic Republic</h2>
          <hr className="hr_article article_body_font"/>
          <div className="article_body_font">
            
          <p>
          This site is was built to help solo founders who want to launch their own startup (or figure out if the one they are working on is worth the time), or 
          even just workers who want more freedom by moving to a remote position. Much of what we are told - from books, seminars, even from venture capitalists 
          themselves - is very wrong and will lead to failure.
          </p>

          <p>
          In short, you've likely landed here because you want freedom. You are not alone. If this is your visit, you might want to check out 
          &nbsp;<a href="/article/1--Youve-Been-Trained-To-Solve-The-Wrong-Problems">I Hate My Job, Now What?</a> or 
          &nbsp;<a href="/article/2--Start-With-a-Market,-Not-an-Idea-(Youre-Doing-It-Backwards)">Start With A Market, Not An Idea</a>. 
          </p>

          <p>
          The goal is to provide a consitent, reliable, repeatable process to gaining your version of freedom - which in most 
          cases means 'freedom from having to work for someone else'. However it can also mean 'freedom from being paycheck to paycheck' 
          or just 'freedom to work where I want without having to commute'.
          </p>

          <p>
          Atomic Republic was created by Marcus Shockley, as a community to help as many startup solo founders and remote workers break free of working for 
          someone else. Marcus has created multiple startups and following an exit to a capital markets firm, joined the firm for over a year to learn more 
          about venture capital and capital markets from the 'supply side'. He also has over 25 years working in technology for both large Fortune 500 firms and tiny startups. 
          But this isn't a biography site, it's built for the community. You can find more of Marcus's work and social media at <a href="https://linktr.ee/marcus_shockley" target="_blank">linktr.ee/marcus_shockley</a> if you choose. 
          </p>
            </div>
          </Col>
      </Row>
  </Container>
  </div>

  /*
  <div style={{width:'100%', backgroundColor:'#dddddd'}}>
    <Container className="single_page_post" style={{position:'relative', zIndex:'10', elevation:'10',backgroundColor:'#dddddd' }}>  
      <Row>
        <Col className="col-xs-12 col-sm-12 col-md-12 rounded_content_block" 
          style={{ marginTop:'10px'}}>
          <div className='about' style={{marginTop:'30px', paddingLeft:'20px', paddingRight:'20px'}}>
            <h1 className="about_headline">What the Heck is PandaBravo?</h1>
            <hr className='hr_about'/>
            <p>
              PandaBravo is a tool for business owners, event operators or anyone who needs to send 
              immediate real-time alerts to their customers or subscribers. <br/>
            </p>
            <p>
              Posting on social Media tools such as Twitter, Instagram or FaceBook will not present 
              your followers with real-time updates; those applications default display algorithms 
              will often not show your update in real-time, or in order, leaving you at the mercy of 
              hoping your users see the update in time.
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/>
              asdasdas asdasd asdasdasd a display <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              sdasdasdasdasdasd  <br/>
            </p>
        </div>
      </Col>
        <Col className="col-xs-0 col-sm-0 col-md-0"/>
      </Row>
    </Container>
  </div>
  */
  );


export default About