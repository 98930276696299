import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import '../classes/GeneralClasses.js';
import APIManager from '../classes/APIManager';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

var apiManager = new APIManager();

class ArticleList extends React.Component {

  constructor(props) {
    super(props); 
      this.state = {
        articles: [],
        filter: props.filter,
        page: props.page, 
        articles_loading: true
      }
  }

  async componentDidMount() {

  }

  async getArticles() {

    await apiManager.GetArticles(this.props.page)
    .then((resp) => {

        this.setState({articles:resp});
        this.setState({articles_loading: false})

    });

  }


  render() {
   
    if(this.props.articles_loading)
        {
            return (
                <div className="blog_list_main" style={{width:'100%', position:'relative', 
                backgroundColor:'#fff', minHeight:'600px', borderRadius:'25px', 
                marginTop:'20px', marginBottom:'20px', textAlign:'center'}}>
                
                LOADING...

                </div>
            )
        }
        else 
        {

            return (
            <div className="single_page_post" style={{width:'100%', position:'relative', 
                backgroundColor:'#ddd', minHeight:'600px'}}>
                <Container 
                    style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
                <Row>
                    <Col className="rounded_content_block article_content_box">               
                    <h2 className="blog_list_headline">Essay List</h2>
                    <hr className="hr_article article_body_font"/>
                    <div className="article_body_font">
                        <p>
                            LOADED
                        </p>          
                    </div>
                    </Col>
                </Row>
                </Container>
            </div>
        );
    }
    
  }

}

export default ArticleList