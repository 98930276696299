import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useParams, useRouteMatch } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import APIManager from '../classes/APIManager';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';

var apiManager = new APIManager();
var height = '450px';
var width = '540px';

class EmailSignup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            height: props.height,
            width: props.width
        }
    }

    async componentDidMount()
    {
        /*
        await apiManager.GetLatestArticle()
        .then((response) => {

            this.state.current_article = response;
            this.setState({current_article:response});

           

        });
        */
    }

    render() {

            if(this.state.width != null)
            {
                width = this.state.width;
            }

            if(this.state.height != null)
            {
                height = this.state.height;
            }

            return (
     
                <div>
                   <iframe scrolling="no" 
                    src="https://3c6a690d.sibforms.com/serve/MUIEAA1K67ytn4sfPVjoOlwtEMMfe8Dtdoc1VW_q5A9OG2NHhu2iUlaay2aVKdPa29XbEyzNkvKYE6aDlO_jkNa6jrHugtPdXZJQIKym3xbzF7oEqZ51AqBDY7iKPNVaPYwrVF2thbDkJn49ILNt8p9v1EpGio6aQtM6uq4fKGa3o91PSYJ25wrdPAwGPLDDkqW7hji9V26LZ7H0" frameborder="0" scrolling="auto" 
                    allowfullscreen 
                    style={{display:'block', marginLeft:'auto', marginRight:'auto', 
                        maxWidth:'100%', width:`${width}`, 
                        height:`${height}`, overflow: 'hidden'}}>
                    </iframe>
                </div>

            )           

      }
}

export default EmailSignup;



