import React from 'react';

export default class Article {
    constructor(id, title, body, blurb, publishDate) {
      this.id = id;
      this.title = title;
      this.body = body;
      this.blurb = blurb;
      this.publishDate = publishDate;
      this.Author = Author;
    }

    GetSnakeCaseTitle()
    {
      var snakeCaseTitle = this.title.replaceAll("'","").replaceAll(" ", "-");
      return snakeCaseTitle;
    }

    GetBlurb()
    {
      /*
      var blurb = "";
      var blurbLen = 300; //default length

      if(this.body.length < 300)
      {
        blurb = this.body;
      }
      else 
      {
        blurb = this.body.substring(0, blurbLen) + "...";
      }
      */

      return this.blurb;

    }
    
    Id()
    {
      return this.Id;
    }

    Title()
    {
      return this.Title;
    }

    Author()
    {
      return this.Author;
    }

}

export class Author {
  constructor(Id, Name, Blurb, TwitterHandle){
    this.Id = Id;
    this.Name = Name;
    this.Blurb = Blurb;
    this.TwitterHandle = TwitterHandle;
  }

  Id()
  {
    return this.Id;
  }

  Name()
  {
    return this.Name;
  }

  Blurb()
  {
    return this.Blurb;
  }

  TwitterHandle()
  {
    return this.TwitterHandle;
  }

}

export class TwitterCommunity {
  constructor(Id, Name, Description, Enrollment, LinkURL, ImgSrcURL, Active){
    this.Id = Id;
    this.Name = Name;
    this.Description = Description;
    this.Enrollment = Enrollment;
    this.LinkURL = LinkURL;
    this.ImgSrcURL = ImgSrcURL;
    this.Active = Active;
  }

  Id()
  {
    return this.Id;
  }

  Name()
  {
    return this.Name;
  }

  Description()
  {
    return this.Description;
  }

  Enrollment()
  {
    return this.Enrollment;
  }

  LinkURL()
  {
    return this.LinkURL;
  }

  ImgSrcURL()
  {
    return this.ImgSrcURL;
  }

  Active()
  {
    return this.Active;
  }

}