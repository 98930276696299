import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Products = () => (

  <div className="single_page_post" style={{width:'100%', position:'relative', 
  backgroundColor:'#ddd', minHeight:'600px'}}>
  <Container 
      style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
      <Row>
          <Col className="rounded_content_block article_content_box">               
          <h2 className="blog_list_headline">Products</h2>
          <hr className="hr_article article_body_font"/>
          <div className="article_body_font">
              <Row>
                <Col>
                  <div className="box_shadow" style={{ width:'280px', height: '400px', border: 'solid 1px #d4d4d4'}}> 
                    <img src="https://atomic-republic.b-cdn.net/FoolProof-Market-Finding-Framework.png" style={{width: '100%'}} ></img>
                    <div style={{width: '100%', padding:'5px'}}>
                      <span className="product_title">The Foolproof Market Finding Framework </span>
                      <span className="product_text_general">is a self-paced course designed to systematically 
                      find, evaluate and develop products for new markets for the best chance at product-market fit.<br/>
                      <br/>
                      This course  is currently not accepting new enrollees.<br/>
                      <div style={{width:'100%', textAlign:'center'}}>
                      <a href="emailsignuppage" className="resources_list_secondary_item"><Button variant="outline-secondary">Wait List</Button></a>
                      </div>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="box_shadow" style={{ width:'280px', height: '400px', border: 'solid 1px #d4d4d4'}}> 
                    <img src="https://atomic-republic.b-cdn.net/Twitter-Community-Search.png" style={{width: '100%'}} ></img>
                    <div style={{width: '100%', padding:'5px'}}>
                      <span className="product_title">Twitter Community Search </span>
                      <span className="product_text_general">is a free tool where you can search and find 
                      relevant communities for a niche or market.<br/>
                      <br/>
                      <div style={{width:'100%', textAlign:'center'}}>
                      <a href="twittercommunities" className="resources_list_secondary_item"><Button variant="outline-secondary">Explore</Button>{' '}</a>
                      </div>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                </Col>
                <Col>
                </Col>
              </Row>
            </div>
          </Col>
      </Row>
  </Container>
  </div>

  /*
  <div style={{width:'100%', backgroundColor:'#dddddd'}}>
    <Container className="single_page_post" style={{position:'relative', zIndex:'10', elevation:'10',backgroundColor:'#dddddd' }}>  
      <Row>
        <Col className="col-xs-12 col-sm-12 col-md-12 rounded_content_block" 
          style={{ marginTop:'10px'}}>
          <div className='about' style={{marginTop:'30px', paddingLeft:'20px', paddingRight:'20px'}}>
            <h1 className="about_headline">What the Heck is PandaBravo?</h1>
            <hr className='hr_about'/>
            <p>
              PandaBravo is a tool for business owners, event operators or anyone who needs to send 
              immediate real-time alerts to their customers or subscribers. <br/>
            </p>
            <p>
              Posting on social Media tools such as Twitter, Instagram or FaceBook will not present 
              your followers with real-time updates; those applications default display algorithms 
              will often not show your update in real-time, or in order, leaving you at the mercy of 
              hoping your users see the update in time.
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/>
              asdasdas asdasd asdasdasd a display <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              sdasdasdasdasdasd  <br/>
            </p>
        </div>
      </Col>
        <Col className="col-xs-0 col-sm-0 col-md-0"/>
      </Row>
    </Container>
  </div>
  */
  );


export default Products