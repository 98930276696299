import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import '../classes/GeneralClasses.js';
import APIManager from '../classes/APIManager';
import AppLoading from '../ui-base/AppLoading';
import EmailSignup from '../ui-base/EmailSignup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


class EmailSignupPage extends React.Component {

    constructor(props) {
      super(props); 
        this.state = {
            pageLoaded: false,
        };
  

    }
  
    async componentDidMount() {

      this.setState({pageLoaded:true});

    }
  
  
    render() {
  
    if(!this.state.pageLoaded)
    {
      return (
        <div>
           <AppLoading status={this.state.status} apiLoaded={this.state.apiLoaded}/>
        </div>
      )
    } 
    else{ 
      return (
        <div className="single_page_post" style={{width:'100%', position:'relative', 
            backgroundColor:'#ddd', minHeight:'600px'}}>
        <Container 
            style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
          <Row>
            <Col className="rounded_content_block article_content_box">  
                
                        <EmailSignup />
              </Col>
              
            </Row>
            
          </Container>
        </div>
      );
    }
      
    }
  
  }
  
  export default EmailSignupPage