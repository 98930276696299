import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useParams, useRouteMatch } from 'react-router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import APIManager from '../classes/APIManager';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';

var apiManager = new APIManager();

class IntroBlurb extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articles: props.articles
        }
    }

    async componentDidMount()
    {
        /*
        await apiManager.GetLatestArticle()
        .then((response) => {

            this.state.current_article = response;
            this.setState({current_article:response});

           

        });
        */
    }

    render() {

            return (
     
                <div>
                    <h2 className="blog_list_headline">The Atomic Republic</h2>
                        <hr className="hr_article"/>
                        <p className="article_body_font">                           
                           <p >You are not alone.</p>
                            <p>If you're looking for independence in
                            your work and life, then you've come to the right place. Atomic
                            Republic is not a place for those who merely dream; it is a place for
                            those who are actively seeking (and working) to be able to sustain
                            themselves profitably with their own work, while also having the
                            freedom to choose when and where they work. It takes money to buy
                            freedom, and Atomic Republic is based on the idea that you can work
                            on your own terms, with who you want, when you want, and where you
                            want. You are autonomous but you are not alone.&nbsp;&nbsp;</p>                          
                        
                            <p>
                                It is not a simple path to navigate from dependence on a job to independence, 
                                but it is a repeatable one; You will probably have to unlearn things you took 
                                as absolute truths, put in the work to adapt to a process that may seem counter-intuitive,
                                but it is possible to follow these steps and start your own business, side hustle, 
                                remote work and independence.
                            </p>
                            <p>
                                If this is your first time here, you should 
                                probably start with <a href="/article/2--Start-With-a-Market,-Not-an-Idea-(Youre-Doing-It-Backwards)">
                                this</a> to understand the difference between what you've probably been told is the way to create 
                                a startup and what you should actually do if you want to be successful.
                            </p>
                        </p>
                </div>

            )           

      }
}

export default IntroBlurb;