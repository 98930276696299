import React from 'react';

import Footer from '../ui-base/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import EmailSignup from '../ui-base/EmailSignup.js'
import APIManager from '../classes/APIManager';
import LatestHeadlines from '../classes/LatestHeadlines';
import LatestArticle from '../classes/LatestArticle';
import IntroBlurb from '../classes/IntroBlurb';

var apiManager = new APIManager();

export default class Home extends React.Component { 
  
    constructor(props) {
        super(props);
       
        this.state = {
            currentUser: null
          }

    };

    componentDidMount(){

    }

    render(){

        var filter = 'all';
        var page = 1;

        return (
            <div className="single_page_post" style={{width:'100%', position:'relative', 
            backgroundColor:'#ddd', minHeight:'600px'}}>
           
                <Container style={{paddingTop:'10px'}}> 
                    <Row style={{padding:'0px'}}>
                        <Col className="rounded_content_block article_content_box"> 
                            {/*<Row>
                                <span class="d-none d-xxl-inline badge bg-dark">xxl</span>
                                <span class="d-none d-xl-inline d-xxl-none badge bg-dark">xl</span>
                                <span class="d-none d-lg-inline d-xl-none badge bg-dark">lg</span>
                                <span class="d-none d-md-inline d-lg-none badge bg-dark">md</span>
                                <span class="d-none d-sm-inline d-md-none badge bg-dark">sm</span>
                                <span class="d-sm-none d-inline badge bg-dark">(xs)</span>
                            </Row>*/}
                            <Row>
                                <Col sm={12} lg={7}>
                                    <IntroBlurb /> 
                                </Col>
                                <Col sm={12} lg={5} className="d-none d-lg-block">
                                    <EmailSignup />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-none d-md-inline d-lg-none">
                                  <EmailSignup height="480px" width="540px"/>
                                </Col>
                                <Col sm={12} className="d-none d-sm-inline d-md-none">
                                  <EmailSignup height="480px" width="540px"/>
                                </Col>
                                <Col sm={12} className="d-sm-none">
                                  <EmailSignup height="480px" width="540px"/>
                                </Col>
                            </Row>
                            {/*
                            <Row>
                                <Col sm={12} className="d-none d-sm-inline d-md-none">
                                  <EmailSignup height="480px" width="540px"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="d-sm-none">
                                  <EmailSignup height="480px" width="540px"/>
                                </Col>
                            </Row>
                            */}
                            <Row>
                                Latest Articles<br/>
                                <hr/>
                                <LatestHeadlines filter={filter} page={page}/>
                            </Row>
                            <Row>
                                <Footer />
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

/*

 <div style={{width:'100%', backgroundColor:'#ddd', 
                minHeight:'800px', position:'relative', 
                display:'block', overflow:'auto',
                top:'0px'}}>



import BlogListFull from '../classes/BlogListFull';
*/
