import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import BlogListFull from '../classes/BlogListFull';
import ArticleList from '../ui-base/ArticleList.js';

var filter = '';

const Blog = (page) => (

    

    <div style={{width:'100%', backgroundColor:'#ddd', 
    minHeight:'800px', position:'relative', 
    display:'block', overflow:'auto',
    top:'0px'}}>
        <Container style={{padding:'0px;'}}>
            <Row style={{padding:'0px'}}>
                <BlogListFull filter={filter} page={page}/><br/>
            </Row>
        </Container>
    </div>
    );


export default Blog