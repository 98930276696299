import React from 'react';
import Button from 'react-bootstrap/Button'

const Trigger = ({ triggerText, buttonRef, showModal }) => {
  return (
    <Button
      variant="outline-secondary"
      className="btn btn-md center modal-button"
      ref={buttonRef}
      onClick={showModal}
    >
      {triggerText}
    </Button>
  );
};
export default Trigger;