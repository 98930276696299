import React from 'react';
import { Switch, Route } from 'react-router';
import Home from './ui-base/Home';
import About from './ui-base/About';
import Blog from './ui-base/Blog';
import Products from './ui-base/Products';
import Resources from './ui-base/Resources';
import TwitterCommunities from './ui-base/TwitterCommunities';
import TwitterCommunitiesInformation from './ui-base/TwitterCommunitiesInformation';
import PostPageSingle, { GetPostPageSingle } from './ui-base/PostPageSingle';
import EmailSignupPage from './ui-base/EmailSignUpPage';
 
export default (
    // Switch is added in v4 react-router
    <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/about' component={About}></Route>
        <Route exact path='/articles' component={Blog}></Route>
        <Route exact path='/resources' component={Resources}></Route>
        <Route exact path='/products' component={Products}></Route>
        <Route exact path='/twittercommunities' component={TwitterCommunities}></Route>
        <Route exact path='/twittercommunitiesinformation' component={TwitterCommunitiesInformation}></Route>
        <Route exact path='/emailsignuppage' component={EmailSignupPage}></Route>
        <Route path='/about2' component={() => { 
     window.location.href = 'https://pandabravo.com'; 
     return null;
}}/>
        <Route path='/article/:article_id--:article_title' component={GetPostPageSingle}/>
        <Route /> // No-match case
    </Switch>
);

