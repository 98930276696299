import React from 'react';

import axios from 'axios';


export function getArticles()
{
   const articles =  [
    {id:"1", title: "Title 1", body: "The body of article 1"},
    {id:"2", title: "Title 2", body: "The body of article 2"},
    {id:"3", title: "Title 3", body: "The body of article 3"},
  ]

  return articles;
}

export const testAxios = async () =>
  async () => {

    let res = await axios({
        method: 'GET',
        url: 'https://httpbin.org/get',
        headers:{
          Accept: 'application/json',
        }
      });
 
    return res;
  };

const url = ``;
//const apiCheckResult = '';
//var healthCheck;

/*
function checkApiHealth()
{
    url = `https://api.notifibot.com/api/1.0/Home/TestConnect/`;

    axios.get(url)
    .then(res => {
            healthCheck = res.data;
            const isAwaiting = false;
            
            this.setState({ healthCheck, isAwaiting });
          })
          .then()
}
*/

var apiHealthy;
var res;

var tempUrl;
var tempUrl2;

export const checkIsHealthy = async () =>{
    return await isAPIHealthy();
}

export const isAPIHealthy = async () => {

    tempUrl = `https://api.notifibot.com/api/1.0/Home/TestConnect/`;
    //tempUrl2 = `https://jsonplaceholder.typicode.com/posts`;

     await axios.get(tempUrl)
        .then(resp => {
            //console.log(resp.data);
            apiHealthy = resp.data;

        })
        .catch(err => {
            // Handle Error Here
            console.error(err);
            apiHealthy = 'FALSE';
        });

    return '<>' + apiHealthy + '</>';
}

/*
const sendGetRequest = async () => {
    try {
        const resp = await axios.get('https://jsonplaceholder.typicode.com/posts');
        console.log(resp.data);
    } catch (err) {
        // Handle Error Here
        console.error(err);
    }
};

sendGetRequest();
*/

/*
var isAPIHealthy = new Promise(function(setAPIHealthy, setAPIUnhealthy){

    url = `https://api.notifibot.com/api/1.0/Home/TestConnect/`;

    axios.get(url)
    .then(res => {
        //const healthCheck = res.data;
        console.log(res);
        setAPIHealthy(true);
      })

    //call the resolve method if the method succeeds
    setAPIHealthy(true);
})
isAPIHealthy.then(bool => console.log('Bool is true'))
*/

/*
var promise = new Promise(function(resolve, reject) {
    // call resolve if the method succeeds
    resolve(true);
  })
  promise.then(bool => console.log('Bool is true'))
*/

  /*
getCurrentTime()
  .then(currentTime => getCurrentTime())
  .then(currentTime => {
    return 'It is now: ' + currentTime;
  })
  // this logs: "It is now: [current time]"
  .then(currentTimeMessage => console.log(currentTimeMessage))
  .catch(err => console.log('There was an error:' + err))
*/

var api_url; 

export class CheckAPI extends React.Component {
    state  = {
        apiResult: null,
        apiHealthy: false,
        checkComplete: false  
    }

    api_url = `https://api.notifibot.com/api/1.0/Home/TestConnect/`;

    componentDidMount() {

        let url = api_url;
  
        axios.get(url)
          .then(res => {
            const apiResult = res.data;
            this.setState({ apiResult, checkComplete: true })
            
          })
      }

      CheckComplete()
      {
          if(this.state.checkComplete)
          {
              //this.state.status = "LOADED";
          }
          else 
          {
              //this.state.status = "NOT LOADED";
          }
  
          return this.state.checkComplete;
      }

}

export function getURL()
{
    const apiURL_1 = `https://jsonplaceholder.typicode.com/users`;
    const apiURL_2 = `https://api.notifibot.com/api/1.0/Home/TestConnect/`;
    const currentAPI_url = apiURL_1;

    return currentAPI_url;
}

export class PersonList2 extends React.Component {
    state = {
      persons: [],
      healthCheck: null
    }
  
    //url = `https://jsonplaceholder.typicode.com/users`;

    componentDidMount() {

      let url = getURL();

      axios.get(url)
        .then(res => {
          const persons = res.data;
          this.setState({ persons });
        })
    }
  
    render() {
      return (
        <ul>
          { this.state.persons.map(person => <li>{person.name}</li>)}
        </ul>
      )
    }
  }
