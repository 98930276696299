import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { BrowserRouter, NavLink, Switch, Route, useParams, useRouteMatch, withRouter, Link } from 'react-router-dom';
//import Button from 'react-bootstrap/Button';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import APIManager from '../classes/APIManager';
import Article from '../classes/GeneralClasses';
import BlogEntry from '../classes/BlogEntry';
import PostPageSingle from '../ui-base/PostPageSingle';

var apiManager = new APIManager();
var linkURL;
var totalPages = 0;
var currentPage = 1;
var resultsPerPage = 3;

class BlogListFull extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            articles: [],
            filter: props.filter,
            page: props.page, 
            articles_loading: true
        }
    }

    async componentDidMount(){

        await apiManager.GetArticleTotalPages(resultsPerPage)
        .then((response) => {

            totalPages = response.data;

        }); 

        await apiManager.GetArticles()
        .then((resp) => {

            this.setState({articles:resp});

            //this.state.articles = resp;
            
            //this.state.articles_loading = false;
            this.setState({articles_loading: false})

            //this.updateArticles(resp);
        });
    
    }


    render() {

        const { path, url } = this.props.match;

        //match = useRouteMatch();

        if(this.props.articles_loading)
        {
            return (
                <div className="blog_list_main" style={{width:'100%', position:'relative', 
                backgroundColor:'#fff', minHeight:'600px', borderRadius:'25px', 
                marginTop:'20px', marginBottom:'20px', textAlign:'center'}}>
                
                LOADING...

                </div>
            )
        }
        else 
        {

            return (


                    <div className="blog_list_main" style={{width:'100%', position:'relative', 
                        backgroundColor:'#fff', minHeight:'600px', borderRadius:'25px', 
                        marginTop:'20px', marginBottom:'20px'}}>
                    <BlogRouterMain articles={this.state.articles} />
                    {this.state.articles.map(function(item, i){
                    
                        linkURL = `/article/` + item.id + "--" + item.GetSnakeCaseTitle();

                        return( 
                                <Container style={{padding:'0', position:'relative',top:'0px', 
                                    paddingTop:'10px', paddingBottom:'10px'}}>
                                    <Row >
                                        <Col>
                                            <BlogEntry article_id={item.id}/><br/>
                                        </Col>
                                    </Row>                       
                                </Container>
                            )
                    })}
                    </div>
                
            )
            }
      }
}

var routerPath;

class BlogRouterMain extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            articles: props.articles,
            article_id: props.article_id
        }
    }

    render(){

        return (
            <div>
            <Switch>
            {/*<Route path='/GetPostPageSingle/:article_id'/>*/}
            {/*}
             {this.state.articles.map((item, i) => (
                    routerPath = `/GetPostPageSingle/:article_id`,
                    <Route path={routerPath} element={<PostPageSingle article_id={item.id}/>}/>
                ))}
             */}
            </Switch>
            </div>

        )
    }
}  


//const BlogRouterMain = (articles) => (


  
//);

//export default BlogListFull;
export default withRouter(BlogListFull);