import React from 'react';
import logo from './logo.svg';
import './App.css';
import './fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route, Switch } from 'react-router-dom';

//import { BrowserRouter, NavLink, Switch, Route } from 'react-router-dom';
import APIChecker from './classes/APICheck';
import { getArticles, CheckAPI, isAPIHealthy, checkIsHealthy } from './actions/actions'

//import Button from 'react-bootstrap/Button';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
import BlogListFull from './classes/BlogListFull';
import Header from './ui-base/Header';
import Footer from './ui-base/Footer';
import AppUnavailable from './ui-base/AppUnavailable';
import AppLoading from './ui-base/AppLoading';

import Navigation from './ui-base/Navigation';
import Home from './ui-base/Home';
import About from './ui-base/About';
import PostPageSingle, { GetPostPageSingle } from './ui-base/PostPageSingle';

const router = require('./router').default;

var apiHealthCheck;
var apiChecker;

var logo_img_blk = "https://atomic-republic-atomic6.b-cdn.net/LOGO-BLACK_ON_WHITE_200w.png";
var logo_img_wht = "https://atomic-republic-atomic6.b-cdn.net/LOGO-WHITE_ON_TRANSP_200w.png";
var logo_url = logo_img_wht;

export default class App extends React.Component { 

  state = {
    articles: [],
    isAPIHealthy: false,
    apiLoaded: false,
    loaded: false,
    status: "LOADING",
    apiResult: 'NONE'
  }

  constructor(props) {
    super(props);
    this.APIHealthy = this.APIHealthy.bind(this);
    this.ApiLoaded = this.ApiLoaded.bind(this);
  };

  componentDidMount() {

    apiChecker = new APIChecker();

    this.state.loaded = false;

    var obj = apiChecker.CheckApiHealth()
    .then(
      resp => {     
        this.setState({apiResult: resp.data}) 
        this.setState({apiLoaded: true}) 
        this.setState({loaded: true}) 
      })
      .catch(err => {
        console.error(err);
    });

  }

  APIHealthy()
  {
      return this.state.apiLoaded;
  }

  ApiLoaded()
  {
    if(this.state.apiLoaded)
    {
      return 'TRUE';
    }
    else 
    {
      return 'FALSE';
    }
  }

  render(){

      if(!this.state.loaded)
      {
        if(this.state.status === "LOADING")
        {
          return <AppLoading status={this.state.status} apiLoaded={this.state.apiLoaded}/>
        }
        else 
        {
          return <AppUnavailable/>
        }

      }
      else 
      {
        return (
          <div> 
          <Header />         
          {router}
          {/*<Main />*/}
        </div>
        )
      } 
    
    }

  }

