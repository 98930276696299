import React from 'react';
import { BrowserRouter, NavLink, Switch, Route } from 'react-router-dom';
import './Header.css';
import '../fonts.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navigation from './Navigation';
import LatestHeadlines from '../classes/LatestHeadlines';

var currentYear;
var displayHTML;

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articles: props.articles
        }

    }
    
    render() {

        currentYear = new Date().getFullYear();
        displayHTML = `&copy;` + currentYear + ` Solo Atlantic, LLC`;

        return (
            <Container style={{position:'absolute', left:'0',
                bottom:'0',
                right:'0', width:'100%'}}>
                    <Col className="d-md-grid col-md-12" style={{border:'none', textAlign:'center', fontSize:'11px'}}>
                    <span dangerouslySetInnerHTML={{ "__html": displayHTML}} /> 
                    </Col>
            </Container>

            
        )
    }
}

export default Footer;
