import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class AppUnavailable extends React.Component { 

    render() {
        return (
            <Container fluid>
            <Row className="mx-auto">
                <Col className="col-4"/>
                <Col className="col-4" style={{textAlign:'center'}}>App is not currently available</Col>
                <Col className="col-4"/>
            </Row>
            </Container>
        )
    }
}