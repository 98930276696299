import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { BrowserRouter, NavLink, Switch, Route, useParams, useRouteMatch, withRouter, Link } from 'react-router-dom';
import APIManager from '../classes/APIManager';
import AppLoading from '../ui-base/AppLoading';
import EmailSignup from '../ui-base/EmailSignup.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Article from './GeneralClasses';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';

var apiManager = new APIManager();
var linkURL;

class BlogEntry extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            article_id: this.props.article_id,
            current_article: null,
            //articles: props.articles
        }
    }

    async componentDidMount()
    {
        await apiManager.GetArticle(this.state.article_id)
        .then((response) => {

            this.state.current_article = response;
            this.setState({current_article:response});
        });
    }

    render() {

        if(this.state.current_article == null)
        {
            return (
                <div>
                   <AppLoading status={this.state.status} apiLoaded={this.state.apiLoaded}/>
                </div>
            )
        }
        else {

            //linkURL = `/article/` + this.state.current_article.id;
            linkURL = `/article/` + this.state.current_article.id + "--" + this.state.current_article.GetSnakeCaseTitle();

            return (
                <Container>                
                    <Row>                    
                        <Col>
                        <br/>
                            {/*<h2 className="blog_list_headline">{this.state.current_article.title}</h2>*/}
                            <Link to={linkURL} className="blog_list_headline">{this.state.current_article.title}</Link><br/>
                                <hr/>
                            <p className="article_body_font">
                            {ReactHtmlParser(this.state.current_article.body)}<br/>
                            </p>
                        </Col>                   
                    </Row>
                </Container>

            )            
        }

      }
}

export default BlogEntry;
