import React from 'react';

export const TwitterCommunityForm = ({ onSubmit, formMessage, formSubmitted }) => {

  
  if(formSubmitted)
  {
    return (
      <div>Thank you for the submission.</div>
    );
  }
  else 
  {

    return (


      <form onSubmit={onSubmit}>
        <div className="form-group" style={{padding:'10px'}}>
          <div style={{width:'100%', textAlign:'center'}}>
          Enter the information to submit a Twitter Community for review:<br/><br/>
          </div>
          <label htmlFor="name">Twitter Community Name</label>
          <input className="form-control" id="name" />
        </div>
        <div className="form-group" style={{padding:'10px'}}>
          <label htmlFor="link">Twitter Community Link</label>
          <input
            type="text"
            className="form-control"
            id="link"
            placeholder=""
          />
        </div>
        <div style={{textAlign:'center', color: 'red'}}>
            {formMessage}
        </div>
        <div className="form-group" style={{padding:'10px'}}>
          <button className="form-control btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
    );

  }
};
export default TwitterCommunityForm;
