import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useParams, useRouteMatch } from 'react-router';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import APIManager from '../classes/APIManager';
import Article from '../classes/GeneralClasses';
import EmailSignup from '../ui-base/EmailSignup.js'
import '../ui-base/GeneralStyle.css';
import '../fonts.css';

var apiManager = new APIManager();

export function GetPostPageSingle(){

    let { article_id } = useParams();
    
    return <PostPageSingle article_id={article_id}/>
}

class PostPageSingle extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            article_id: this.props.article_id,
            current_article: null,
            //articles: props.articles
        }
    }

    async componentDidMount()
    {
        await apiManager.GetArticle(this.state.article_id)
        .then((response) => {

            this.state.current_article = response;
            this.setState({current_article:response});
        });
    }

    render() {

        var postDate;
        var authorHTML = ``;

        if(this.state.current_article == null)
        {
            return (
                <div>
                    LOADING...
                </div>
            )
        }
        else {

            postDate = moment(this.state.current_article.publishDate).format('MM-DD-YYYY')

            if(this.state.current_article.Author != null)
            {
                if(this.state.current_article.Author.TwitterHandle != null)
                {
                    authorHTML = `&nbsp;|&nbsp;<a href="https://www.twitter.com/` + this.state.current_article.Author.TwitterHandle + `" target="_blank" style="text-decoration:none;color:#d4d4d4">` + this.state.current_article.Author.Name + `</a>`;
                }
                else 
                {
                    authorHTML = this.state.current_article.Author.Name;
                }
            }

            return (
                <div className="single_page_post" style={{width:'100%', position:'relative', 
                    backgroundColor:'#ddd', minHeight:'600px'}}>
                    <Container 
                        style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
                        <Row>
                            <Col className="rounded_content_block article_content_box">  
                                <Row>
                                    <Col>
                                        <h2 className="blog_list_headline">{this.state.current_article.title}</h2>
                                        <hr className="hr_article"/>
                                        <p className="article_body_font">
                                        <span className="article_byline">
                                        {ReactHtmlParser(postDate)}{ReactHtmlParser(authorHTML)}<br/><br/>
                                        </span>
                                        {/*<p className="article_date">{postDate}</p><br/>*/}
                                        {ReactHtmlParser(this.state.current_article.body)}</p><br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <EmailSignup height="480px" width="540px"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                       
                </Container>
                </div>

            )           
        }

      }
}

export default PostPageSingle;