import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

const Resources = () => (

  <div className="single_page_post" style={{width:'100%', position:'relative', 
  backgroundColor:'#ddd', minHeight:'600px'}}>
  <Container 
      style={{marginTop:'0px', position:'relative', top:'0px', paddingTop:'10px'}}>                      
      <Row>
          <Col className="rounded_content_block article_content_box">               
          <h2 className="blog_list_headline">Resources</h2>
          <hr className="hr_article article_body_font"/>
          <div className="article_body_font">
            <p>
              <span className="resources_header_general">Community Building/Finding Resources:</span><br/>
              <span className="resources_body_general">
                Twitter<br/>
                <a href="twittercommunitiesinformation" className="resources_list_primary_item">Twitter Communities Guide</a><br/>
                <a href="twittercommunities" className="resources_list_secondary_item">Twitter Communities Search</a><br/>
                <a href="https://twitter.com/i/communities/1493795120520564738" className="resources_list_secondary_item" target="_blank">Atomic Startups &amp; Founders Twitter  Community</a> <br/>
                <br/>
                Reddit<br/>
                <a href="https://www.reddit.com/subreddits/search" className="resources_list_secondary_item" target="_blank">Reddit - Subreddit Search Tool</a>

              </span>
            </p>          
            </div>
          </Col>
      </Row>
  </Container>
  </div>

  /*
  <div style={{width:'100%', backgroundColor:'#dddddd'}}>
    <Container className="single_page_post" style={{position:'relative', zIndex:'10', elevation:'10',backgroundColor:'#dddddd' }}>  
      <Row>
        <Col className="col-xs-12 col-sm-12 col-md-12 rounded_content_block" 
          style={{ marginTop:'10px'}}>
          <div className='about' style={{marginTop:'30px', paddingLeft:'20px', paddingRight:'20px'}}>
            <h1 className="about_headline">What the Heck is PandaBravo?</h1>
            <hr className='hr_about'/>
            <p>
              PandaBravo is a tool for business owners, event operators or anyone who needs to send 
              immediate real-time alerts to their customers or subscribers. <br/>
            </p>
            <p>
              Posting on social Media tools such as Twitter, Instagram or FaceBook will not present 
              your followers with real-time updates; those applications default display algorithms 
              will often not show your update in real-time, or in order, leaving you at the mercy of 
              hoping your users see the update in time.
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/>
              asdasdas asdasd asdasdasd a display <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              sdasdasdasdasdasd  <br/>
            </p>
        </div>
      </Col>
        <Col className="col-xs-0 col-sm-0 col-md-0"/>
      </Row>
    </Container>
  </div>
  */
  );


export default Resources