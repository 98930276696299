import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useParams, useRouteMatch } from 'react-router';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import APIManager from '../classes/APIManager';
import Article from '../classes/GeneralClasses';
import '../ui-base/GeneralStyle.css';
import '../fonts.css';

var apiManager = new APIManager();

export function GetPostPageSingle(){

    let { article_id } = useParams();
    
    return <LatestArticle article_id={article_id}/>
}

class LatestArticle extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            article_id: this.props.article_id,
            current_article: null,
            show_blurb: this.props.show_blurb,
            article_body: this.props.article_body
            //articles: props.articles
        }
    }

    async componentDidMount()
    {
        await apiManager.GetLatestArticle()
        .then((response) => {

            this.state.current_article = response;
            this.setState({current_article:response});

            if(this.props.show_blurb === true){
                this.state.articleBody = this.state.current_article.GetBlurb();
                this.setState({articleBody: this.state.current_article.GetBlurb()})
            }
            else 
            {
                this.state.articleBody = this.state.current_article.body;
                this.setState({articleBody: this.state.current_article.body})
            }

        });
    }

    render() {

        var postDate;

        if(this.state.current_article == null)
        {
            return (
                <div>
                    LOADING...
                </div>
            )
        }
        else {

            postDate = moment(this.state.current_article.publishDate).format('MM-DD-YYYY')

            return (
     
                <div>
                    <h2 className="blog_list_headline">{this.state.current_article.title}</h2>
                        <hr className="hr_article"/>
                        <p className="article_body_font">
                            {ReactHtmlParser(this.state.articleBody)}
                            
                        </p>
                </div>

            )           
        }

      }
}

export default LatestArticle;